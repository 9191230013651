<template>
	<div>

	</div>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits } from 'vue';

interface Props{

}
const props = defineProps<Props>();
interface Emit{

}
const emit = defineEmits<Emit>();

</script>
