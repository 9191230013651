import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-339afb01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["ElForm"], {
      "label-position": "top",
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('Chart Type')
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElSelect"], {
              modelValue: $setup.chartType,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.chartType) = $event)),
              onChange: $setup.changeType
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.chartTypes, (op) => {
                  return (_openBlock(), _createBlock($setup["ElOption"], {
                    value: op.value,
                    label: op.label
                  }, null, 8 /* PROPS */, ["value", "label"]))
                }), 256 /* UNKEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        ($setup.chartType !== 'System Chart' && $setup.chartType !== 'Text Editor' && $setup.chartType !== 'Superset')
          ? (_openBlock(), _createBlock($setup["ElFormItem"], {
              key: 0,
              label: $setup.tt('Document')
            }, {
              default: _withCtx(() => [
                _createVNode($setup["DocSelect"], {
                  modelValue: $setup.doctype,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($setup.doctype) = $event)),
                  onChange: $setup.changeDoctype
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true),
        ($setup.chartType !== 'System Chart' && $setup.chartType !== 'Text Editor' && $setup.chartType !== 'Superset')
          ? (_openBlock(), _createBlock($setup["ElFormItem"], {
              key: 1,
              label: $setup.tt('filter')
            }, {
              default: _withCtx(() => [
                _createVNode($setup["Filter"], {
                  visible: $props.visible,
                  modelValue: $setup.filter,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($setup.filter) = $event)),
                  options: $setup.doctype,
                  onChange: $setup.changeFilter
                }, null, 8 /* PROPS */, ["visible", "modelValue", "options"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true),
        ($setup.chartType !== 'System Chart' && $setup.chartType !== 'Text Editor' && $setup.chartType !== 'Superset')
          ? (_openBlock(), _createBlock($setup["ElFormItem"], {
              key: 2,
              label: $setup.tt('Date follow-up filter')
            }, {
              default: _withCtx(() => [
                _createVNode($setup["DateFilter"], {
                  modelValue: $setup.dateFilter,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($setup.dateFilter) = $event)),
                  options: $setup.doctype,
                  reportEndDate: $setup.reportEndDate,
                  reportStartDate: $setup.reportStartDate
                }, null, 8 /* PROPS */, ["modelValue", "options", "reportEndDate", "reportStartDate"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]))
          : _createCommentVNode("v-if", true),
        ($setup.chartType)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.widgets.getOptionComponent($setup.chartType)), { key: $setup.chartType }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    })
  ]))
}