<template>
	<div>
		<ElForm class="form" ref="formRef" :model="form" @submit.prevent :rules="rules">
			<ElFormItem :label="tt('Title')" prop="title">
				<ElInput v-model="form.title" @change="changeTitle" />
			</ElFormItem>
			<ElFormItem :label="tt('Calculation method')" prop="method">
				<ElSelect v-model="form.method" @change="changeMethod">
					<ElOption v-for="f in METHODS" :value="f" :label="tt(f)">
					</ElOption>
				</ElSelect>
			</ElFormItem>
			<ElFormItem :label="tt('X-axis')" prop="xAxis">
				<ElSelect v-model="form.xAxis" @change="changeX" filterable>
					<ElOption v-for="f in fields" :value="f.fieldname" :label="tt(f.label)">
					</ElOption>
				</ElSelect>
			</ElFormItem>
			<ElFormItem v-if="isShowY" :label="tt('Y-axis')" prop="yAxis">
				<ElSelect v-model="form.yAxis" multiple @change="changeY" filterable>
					<ElOption v-for="f in numberFields" :value="f.fieldname" :label="tt(f.label)">
					</ElOption>
				</ElSelect>
			</ElFormItem>
			<ElFormItem :label="tt('X-axis name')" prop="xLabel">
				<ElInput v-model="form.xLabel" @change="changeXLabel" />
			</ElFormItem>
			<ElFormItem :label="tt('Y-axis name')" prop="yLabel">
				<ElInput v-model="form.yLabel" @change="changeYLabel" />
			</ElFormItem>
			<ElFormItem :label="tt('Height')" prop="height">
				<ElInputNumber :modelValue="form.height" @change="changeHeight"></ElInputNumber>
			</ElFormItem>
		</ElForm>
	</div>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits, reactive, watch, inject, computed } from 'vue';

import type { FormInstance, FormRules } from 'element-plus';
import { ElForm, ElSelect, ElOption, ElFormItem, ElInput, ElInputNumber } from 'element-plus';

import type { ChartOptions, ChartProvide } from '../../../../type';
import { numberFieldTypes, notValueField } from '../../helper';
const METHODS = ['Count', 'Sum', 'Value'];

const formRef = ref<FormInstance>();
const tt = __;
interface Props {

}
const props = defineProps<Props>();
interface Emit {
	(event: 'remove'): void;
}
const emit = defineEmits<Emit>();
const chart = inject<ChartProvide>('chart');
const doctype = computed(() => chart?.doc.source_doctype);
const form = reactive({
	title: chart?.doc.options?.title,
	xAxis: chart?.doc.options?.xAxis?.fieldname,
	yAxis: chart?.doc.options?.yAxis?.map(item => item.fieldname),
	xLabel: chart?.doc.options?.xLabel,
	yLabel: chart?.doc.options?.yLabel,
	height: chart?.doc.options?.height,
	method: chart?.doc.options?.method,
});

watch(() => chart?.doc.options, () => {
	form.title = chart?.doc.options?.title;
	form.xAxis = chart?.doc.options?.xAxis?.fieldname;
	form.yAxis = chart?.doc.options?.yAxis?.map(item => item.fieldname);
	form.xLabel = chart?.doc.options?.xLabel;
	form.yLabel = chart?.doc.options?.yLabel;
	form.method = chart?.doc.options?.method;
	form.height = chart?.doc.options?.height;
});

const rules = reactive<FormRules>({
	xAxis: [
		{
			required: true,
			message: __('Please select the x-axis field'),
			trigger: 'change',
		},
	],
	yAxis: [
		{
			required: true,
			message: __('Please select the y-axis field'),
			trigger: 'change',
		},
	],

});
const fields = ref<locals.DocField[]>([]);

watch(doctype, async () => {
	if (!doctype.value) { fields.value = []; return; }
	await new Promise(r => frappe.model.with_doctype(doctype.value!, r));
	const f = frappe.get_doc('DocType', doctype.value)?.fields || [];
	fields.value = f.filter(item => !notValueField.includes(item.fieldtype));
}, { immediate: true });
const numberFields = computed(() => fields.value.filter(f => numberFieldTypes.includes(f.fieldtype)));

function changeTitle(value: string) {
	if (!chart) { return; }
	chart.doc.options.title = value;
}
function changeXLabel(value: string) {
	if (!chart) { return; }
	chart.doc.options.xLabel = value;
}
function changeYLabel(value: string) {
	if (!chart) { return; }
	chart.doc.options.yLabel = value;
}
function changeX(v: string) {
	if (!chart) { return; }
	const xAxis = fields.value.find(item => item.fieldname === form.xAxis);
	chart.doc.options.xAxis = { label: xAxis?.label, fieldname: xAxis?.fieldname, fieldtype: xAxis?.fieldtype };
}
function changeY(v: string[]) {
	if (!chart) { return; }
	const yAxisArr = fields.value.filter(item => v.includes(item.fieldname));
	chart.doc.options.yAxis = yAxisArr.map(item => ({ label: item?.label, fieldname: item?.fieldname, fieldtype: item?.fieldtype }));
}
function changeHeight(value?:number){
	if (!chart) { return; }
	chart.doc.options.height = value;
}
const isShowY = computed(() => form.method !== 'Count');

function changeMethod(v: string) {
	if (!chart) { return; }
	chart.doc.options.method = v;
}

</script>

<style lang='less' scoped>
:deep(.form label) {
	margin-bottom: 0;
}
</style>
