import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, withDirectives as _withDirectives, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f0a21b01"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tools"
}
const _hoisted_2 = {
  class: "chart-container",
  ref: "blockRef"
}
const _hoisted_3 = { class: "relative h-full w-full" }
const _hoisted_4 = {
  key: 1,
  class: "placeholder"
}
const _hoisted_5 = { class: "mb-1 w-[10rem] text-gray-400" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($props.isEditable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode($setup["GripVertical"], {
            class: "drag-handle",
            contenteditable: "false",
            draggable: "true",
            "data-drag-handle": "",
            size: 16
          }),
          _createVNode($setup["BlockActions"], {
            onRemove: _cache[0] || (_cache[0] = $event => ($setup.emit('remove'))),
            editable: $props.isEditable
          }, {
            default: _withCtx((slotProps) => [
              _createVNode($setup["ChartSettingForm"], {
                visible: slotProps.visible
              }, null, 8 /* PROPS */, ["visible"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["editable"])
        ]))
      : _createCommentVNode("v-if", true),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
      ($setup.isShowChart)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent($setup.widgets.getComponent($setup.chart?.doc?.type)), {
            ref: "widget",
            data: $setup.chart?.data,
            options: $setup.chart?.doc.options,
            isEditable: $props.isEditable,
            key: JSON.stringify([$setup.chart?.data])
          }, {
            placeholder: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, _toDisplayString($setup.tt('Incorrectly Configured Component')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["data", "options", "isEditable"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, _toDisplayString($setup.tt('Please configure the chart')), 1 /* TEXT */)
          ]))
    ])), [
      [$setup["vLoading"], $setup.chart?.loading]
    ])
  ], 64 /* STABLE_FRAGMENT */))
}