<template>
	<div class="form">
		<ElForm label-position="top" @submit.prevent>
			<ElFormItem :label="tt('Chart Type')">
				<ElSelect v-model="chartType" @change="changeType">
					<ElOption v-for="op in chartTypes" :value="op.value" :label="op.label"></ElOption>
				</ElSelect>
			</ElFormItem>
			<ElFormItem :label="tt('Document')"
				v-if="chartType !== 'System Chart' && chartType !== 'Text Editor' && chartType !== 'Superset'">
				<DocSelect v-model="doctype" @change="changeDoctype"></DocSelect>
			</ElFormItem>
			<ElFormItem :label="tt('filter')"
				v-if="chartType !== 'System Chart' && chartType !== 'Text Editor' && chartType !== 'Superset'">
				<Filter :visible="visible" v-model="filter" :options="doctype" @change="changeFilter"></Filter>
			</ElFormItem>
			<ElFormItem :label="tt('Date follow-up filter')"
				v-if="chartType !== 'System Chart' && chartType !== 'Text Editor' && chartType !== 'Superset'">
				<DateFilter v-model="dateFilter" :options="doctype" :reportEndDate="reportEndDate"
					:reportStartDate="reportStartDate"></DateFilter>
			</ElFormItem>
			<component v-if="chartType" :is="widgets.getOptionComponent(chartType)" :key="chartType" />
		</ElForm>
	</div>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits, reactive, watch, inject, computed, onMounted, type Ref } from 'vue';

import type { ChartOptions, ChartProvide } from '../../type';
import { ElButton, ElForm, ElSelect, ElOption, ElFormItem } from 'element-plus';

import DocSelect from './DocSelect.vue';
import widgets from './widgets/widgets';
import Filter from './Filter.vue';
import DateFilter from './DateFilter.vue';

const tt = __;

interface Props {
	visible: boolean;
}
const props = defineProps<Props>();
const chart = inject<ChartProvide>('chart');
const reportStartDate = inject<Ref<string>>('reportStartDate');
const reportEndDate = inject<Ref<string>>('reportEndDate');
const doctype = ref<string>();
const chartType = ref<string>();
const filter = ref<any>();
const chartTypes = ref<{ value: string, label: string; }[]>([]);
watch(() => chart?.doc.filter, () => {
	filter.value = chart?.doc.filter;
}, { immediate: true });

const dateFilter = computed({
	set(v: any) {
		if (!doctype.value) { return; }
		chart?.updateDateQuery?.(doctype.value, v);
	},
	get() {
		return chart?.doc.dateFilter;
	}
});
watch(() => chart?.doc.source_doctype, () => {
	doctype.value = chart?.doc.source_doctype || '';
}, { immediate: true });
watch(() => chart?.doc.type, () => {
	chartType.value = chart?.doc.type || '';
}, { immediate: true });

onMounted(async () => {
	chartTypes.value = [
		{ value: 'Bar', label: __('Bar') },
		{ value: 'Table', label: __('Form') },
		{ value: 'Pie', label: __('Pie Charts') },
		{ value: 'Line', label: __('Line') },
		{ value: 'Text', label: __('Text') },
		{ value: 'System Chart', label: __('System charts') },
		{ value: 'Text Editor', label: __('Rich Text') },
	];
	fetch(`/api/resource/${'Guigu Superset Chart'}`).then(function (response) {
		if (response.ok) {
			chartTypes.value.push({
				value: 'Superset', label: 'Superset',
			});
		} else {
			console.log(`Doctype does not exist.`);
		}
	})
		.catch(function (error) {
			console.log('Error occurred while checking Doctype:', error);
		});
});

function changeDoctype(v: string) {
	if (!chart) { return; }
	chart.doc.options = {};
	chart?.updateQuery?.(v);
	chart?.updateDateQuery?.(v);
}

function changeType(v: string) {
	if (!chart) { return; }
	chart.doc.type = v;
}
function changeFilter(v: any) {
	if (!doctype.value) { return; }
	chart?.updateQuery?.(doctype.value, v);
}
</script>

<style lang='less' scoped>
:deep(.form label) {
	margin-bottom: 0;
}
</style>
