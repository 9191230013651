import { createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createBlock($setup["ElForm"], {
    class: "form",
    ref: "formRef",
    model: $setup.form,
    onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
    rules: $setup.rules
  }, {
    default: _withCtx(() => [
      _createVNode($setup["ElFormItem"], {
        label: $setup.tt('Title'),
        prop: "title"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElInput"], {
            modelValue: $setup.form.title,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.title) = $event)),
            onChange: $setup.changeTitle
          }, null, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label"]),
      _createVNode($setup["ElFormItem"], {
        label: $setup.tt('Sort Field'),
        prop: "sort"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElSelect"], {
            modelValue: $setup.form.sort,
            filterable: "",
            defaultFirstOption: "",
            onChange: $setup.changeSort
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sortFields, (f) => {
                return (_openBlock(), _createBlock($setup["ElOption"], {
                  key: f.fieldname,
                  value: f.fieldname,
                  label: $setup.tt(f.label)
                }, null, 8 /* PROPS */, ["value", "label"]))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label"]),
      _createVNode($setup["ElFormItem"], {
        label: $setup.tt('Sorted'),
        prop: "order"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElSelect"], {
            modelValue: $setup.form.order,
            onChange: $setup.changeOrder
          }, {
            default: _withCtx(() => [
              _createVNode($setup["ElOption"], {
                value: "asc",
                label: $setup.tt('Positive Sequence')
              }, null, 8 /* PROPS */, ["label"]),
              _createVNode($setup["ElOption"], {
                value: "desc",
                label: $setup.tt('Reverse Order')
              }, null, 8 /* PROPS */, ["label"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["modelValue"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label"]),
      _createVNode($setup["ElFormItem"], {
        label: $setup.tt('Column'),
        prop: "columns"
      }, {
        default: _withCtx(() => [
          _createVNode($setup["ElTable"], {
            data: $setup.form.columns,
            style: {"width":"100%"}
          }, {
            default: _withCtx(() => [
              _createVNode($setup["ElTableColumn"], {
                label: $setup.tt('Fields')
              }, {
                default: _withCtx(({ row, $index }) => [
                  _createVNode($setup["ElSelect"], {
                    modelValue: row.field,
                    filterable: "",
                    defaultFirstOption: "",
                    "onUpdate:modelValue": $event => ($setup.setFiled($index, 'field', $event))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fields, (f) => {
                        return (_openBlock(), _createBlock($setup["ElOption"], {
                          key: f.fieldname,
                          value: f.fieldname,
                          label: $setup.tt(f.label)
                        }, null, 8 /* PROPS */, ["value", "label"]))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode($setup["ElTableColumn"], {
                label: $setup.tt('Size')
              }, {
                default: _withCtx(({ row, $index }) => [
                  _createVNode($setup["ElInput"], {
                    type: "number",
                    modelValue: row.size,
                    "onUpdate:modelValue": $event => ($setup.setFiled($index, 'size', $event || 0))
                  }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createVNode($setup["ElTableColumn"], {
                width: 45,
                label: $setup.tt('Actions')
              }, {
                default: _withCtx(({ $index }) => [
                  _createVNode($setup["ElButton"], {
                    type: "danger",
                    onClick: $event => ($setup.remove($index)),
                    text: "",
                    icon: $setup.Delete,
                    title: $setup.tt('Delete')
                  }, null, 8 /* PROPS */, ["onClick", "icon", "title"])
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["data"])
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["label"]),
      _createVNode($setup["ElButton"], { onClick: $setup.add }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($setup.tt('Add')), 1 /* TEXT */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["model", "rules"]))
}