import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode($setup["ElForm"], {
      class: "form",
      ref: "formRef",
      model: $setup.form,
      onSubmit: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
      rules: $setup.rules
    }, {
      default: _withCtx(() => [
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('Chart'),
          prop: "chart"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElSelect"], {
              modelValue: $setup.form.chart,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.form.chart) = $event)),
              filterable: "",
              remote: "",
              remoteMethod: $setup.search,
              onFocus: $setup.focus,
              defaultFirstOption: "",
              loading: $setup.loading || $setup.waiting,
              onChange: $setup.changeSystemChart
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.opts, ({ value, label, description }) => {
                  return (_openBlock(), _createBlock($setup["ElOption"], {
                    key: value,
                    label: label,
                    value: value
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(label) + " ", 1 /* TEXT */),
                      _createCommentVNode(" <small v-if=\"description\">{{ description }}</small> ")
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label", "value"]))
                }), 128 /* KEYED_FRAGMENT */))
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["modelValue", "loading"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"]),
        _createVNode($setup["ElFormItem"], {
          label: $setup.tt('Height'),
          prop: "height"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["ElInputNumber"], {
              modelValue: $setup.form.height,
              onChange: $setup.changeHeight
            }, null, 8 /* PROPS */, ["modelValue"])
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["label"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["model", "rules"])
  ]))
}