<template>
	<el-form class="form" ref="formRef" :model="form" @submit.prevent :rules="rules">
		<el-form-item :label="tt('Title')" prop="title">
			<el-input v-model="form.title" @change="changeTitle" />
		</el-form-item>
		<el-form-item :label="tt('Sort Field')" prop="sort">
			<ElSelect :modelValue="form.sort" filterable defaultFirstOption @change="changeSort">
				<ElOption v-for="f in sortFields" :key="f.fieldname" :value="f.fieldname" :label="tt(f.label)" />
			</ElSelect>
		</el-form-item>
		<el-form-item :label="tt('Sorted')" prop="order">
			<ElSelect :modelValue="form.order" @change="changeOrder">
				<ElOption value="asc" :label="tt('Positive Sequence')" />
				<ElOption value="desc" :label="tt('Reverse Order')" />
			</ElSelect>
		</el-form-item>
		<el-form-item :label="tt('Column')" prop="columns">
			<ElTable :data="form.columns" style="width: 100%">
				<ElTableColumn :label="tt('Fields')" #="{ row, $index }">
					<ElSelect :modelValue="row.field" filterable defaultFirstOption
						@update:modelValue="setFiled($index, 'field', $event)">
						<ElOption v-for="f in fields" :key="f.fieldname" :value="f.fieldname" :label="tt(f.label)" />
					</ElSelect>
				</ElTableColumn>
				<ElTableColumn :label="tt('Size')" #="{ row, $index }">
					<ElInput type="number" :modelValue="row.size" @update:modelValue="setFiled($index, 'size', $event || 0)" />
				</ElTableColumn>
				<ElTableColumn :width="45" :label="tt('Actions')" #="{ $index }">
					<ElButton type="danger" @click="remove($index)" text :icon="Delete" :title="tt('Delete')" />
				</ElTableColumn>
			</ElTable>
		</el-form-item>
		<ElButton @click="add">{{ tt('Add') }}</ElButton>
	</el-form>
</template>

<script setup lang='ts'>
import { ref, defineProps, defineEmits, reactive, watch, inject, computed } from 'vue';

import type { FormInstance, FormRules } from 'element-plus';
import { ElForm, ElSelect, ElOption, ElFormItem, ElInput, ElTableColumn, ElTable, ElButton } from 'element-plus';
import type { ChartOptions, ChartProvide } from '../../../../type';
import { notValueField } from '../../helper';
import { Delete } from '@element-plus/icons-vue';

const formRef = ref<FormInstance>();
const tt = __;
interface Props {

}
const props = defineProps<Props>();
interface Emit {
	(event: 'remove'): void;
}
const emit = defineEmits<Emit>();
const chart = inject<ChartProvide>('chart');
const doctype = computed(() => chart?.doc.source_doctype);
const form: {
	title: string,
	sort?: string,
	order?: string;
	columns: { field?: string, size?: string; }[];
} = reactive({
	title: chart?.doc.options?.title,
	sort: chart?.doc.options?.sort,
	order: chart?.doc.options?.order,
	columns: chart?.doc.options?.columns?.map((item: any) => ({ field: item.fieldname, size: item.size })) || [],
});

watch(() => chart?.doc.options, () => {
	form.title = chart?.doc.options?.title;
	form.sort = chart?.doc.options?.sort;
	form.order = chart?.doc.options?.order;
	form.columns = chart?.doc.options?.columns?.map((item: any) => ({ field: item.fieldname, size: item.size })) || [];
});

const rules = reactive<FormRules>({
	columns: [
		{
			required: true,
			message: __('Please select column fields'),
			trigger: 'change',
		},
	],
});
const fields = ref<{ fieldname: string, label: string, fieldtype: string; }[]>([]);
const sortFields = ref<{ fieldname: string, label: string, fieldtype: string; }[]>([]);
watch(doctype, async () => {
	if (!doctype.value) { fields.value = []; return; }
	await new Promise(r => frappe.model.with_doctype(doctype.value!, r));
	const f = frappe.get_doc('DocType', doctype.value)?.fields || [];
	fields.value = f.filter(item => !notValueField.includes(item.fieldtype));
	sortFields.value = [...f.filter(item => !notValueField.includes(item.fieldtype)),
	{
		fieldname: 'creation',
		label: __('Created On'),
		fieldtype: 'Date'
	},
	{
		fieldname: 'modified',
		label: __('Last Modified Date'),
		fieldtype: 'Date'
	},
	];
}, { immediate: true });

function changeTitle(value: string) {
	if (!chart) { return; }
	chart.doc.options.title = value;
}
function changeSort(v: string) {
	if (!chart) { return; }
	chart.doc.options.sort = v;
	form.sort = v;
	chart?.updateChartData?.();
}
function changeOrder(v: string) {
	if (!chart) { return; }
	chart.doc.options.order = v;
	form.order = v;
	chart?.updateChartData?.();
}
function setFiled(index: number, key: any, value: any) {
	if (!chart) { return; }
	const list = [...form.columns];
	const item: any = list[index];
	if (!item) { return; }
	item[key] = value;
	const allFieldNames = fields.value.map(item => item.fieldname);
	const columns = list.filter(item => allFieldNames.includes(item.field || ''))
		.map(c => {
			const f = fields.value.find(item => item.fieldname === c.field)!;
			return { label: f.label, fieldname: f.fieldname, fieldtype: f.fieldtype, size: c.size };
		});
	chart.doc.options.columns = columns;
}
function remove(index: number) {
	if (!chart) { return; }
	const list = [...form.columns];
	if (!list.splice(index, 1).length) { return; }
	const allFieldNames = fields.value.map(item => item.fieldname);
	const columns = list.filter(item => allFieldNames.includes(item.field || ''))
		.map(c => {
			const f = fields.value.find(item => item.fieldname === c.field)!;
			return { label: f.label, fieldname: f.fieldname, fieldtype: f.fieldtype, size: c.size };
		});
	form.columns = list;
	chart.doc.options.columns = columns;
}
function add() {
	form.columns = [...form.columns, {}];
}
</script>

<style lang='less' scoped>
:deep(.form label) {
	margin-bottom: 0;
}

.form {
	margin-bottom: 8px
}
</style>
