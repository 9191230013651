import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fd17e16a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "tableContainerRef",
  class: "table-container"
}
const _hoisted_2 = {
  key: 0,
  class: "title"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($setup.props.options.title )
      ? (_openBlock(), _createElementBlock("h4", _hoisted_2, _toDisplayString($setup.props.options.title), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["ElTable"], {
      headerRowClassName: "report-table-header",
      border: "",
      data: $setup.tableData,
      style: {"width":"100%"},
      height: "100%"
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.columns, (col, index) => {
          return (_openBlock(), _createBlock($setup["ElTableColumn"], {
            prop: col.fieldname,
            label: $setup.tt(col.label),
            width: index === $setup.columns.length - 1 ? ($setup.useWidth ? col.size : undefined) : col.size
          }, null, 8 /* PROPS */, ["prop", "label", "width"]))
        }), 256 /* UNKEYED_FRAGMENT */))
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["data"])
  ], 512 /* NEED_PATCH */))
}